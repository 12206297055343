import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Snackbar from "@mui/material";
import i18n from './i18n';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { translateText } from "./Translator";
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, ListItemText, NativeSelect, OutlinedInput, TextField, Theme, Typography, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
import lang from '../../languages';
import axios from "axios";
import { deleteOrganizationLanguage, getArtifactLabels, getLabels, getLanguages, getLanguagesByOrganization, getPlatformLabels, saveLanguageExpertiseLabels, saveOrganizationLanguages } from "../../services/MultilingualService";
import { getAllGuides } from "../../services/ProfileSettingPageService";
import { useAuth } from "../auth/AuthProvider";
import { useSnackbar } from "../../SnackbarContext";
import { languageDisplayMap } from "../adminMenu/AdminMenu";


interface Language {
  LanguageId: string;
  Language: string;
  LanguageCode: string;
  FlagIcon: string;
}
let letorgLanguages: OrganizationLanguage[] = [];
interface language {
  id: number;
  name: string;
  code: string;
}

export const availableLanguages: language[] = [
 
  { id: 1, name: "Telugu", code: "te" },
  { id: 2, name: "Hindi", code: "hi" },
  { id: 3, name: "Arabic", code: "ar" },
];
type OrganizationLanguage = {
  OrganizationId: string | undefined;
  LanguageId: string ;
  Language: string;
  Id: string;
};

interface LanguageMap {
  [key: string]: string;
}

const Translater = ({ selectedLanguageProp }: { selectedLanguageProp: string }) => {
 
  const [guideTypeOptions, setGuideTypeOptions] = useState<any[]>([]); 
  const [guideTypeOptionsNew, setGuideTypeOptionsNew] = useState<any[]>([]); 
  const [organizationLanguages, setOrganizationLanguages] = useState<OrganizationLanguage[]>(() => {
    const savedLanguages = localStorage.getItem('organizationLanguages');
    return savedLanguages ? JSON.parse(savedLanguages) : [];
  });
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [labels, setLabels] = useState<any>({});
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const [dropdownLanguage, setdropdownLanguage] = useState(30);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [toText, setToText] = useState<string[]>([]);
  const [toLanguage, setToLanguage] = useState<string>('');
  const [languages, setLanguages] = useState<Language[]>([]);
  const [translatedTitle, setTranslatedTitle] = useState(""); 
  const [loading, setLoading] = useState(true);
  const [editLabelKey, setEditLabelKey] = useState(null); 
  const [editLabelValue, setEditLabelValue] = useState("");
  const [type, setType] = useState("Announcements")
  const [selectedData, setselectedData] = React.useState<string[]>([]);
  const handleTypeChange = (event:any) => {
    setType(event.target.value);
};

const [selectedArtifactIds, setSelectedArtifactIds] = useState<string[]>([]);

const languageChange = (event: SelectChangeEvent<typeof selectedData>) => {
  const {
    target: { value },
  } = event;
  setselectedData(typeof value === 'string' ? value.split(',') : value);
  
  };
  const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
  useEffect(() =>
  {
    setselectedData([]);
  },[type])
  
  const { userDetails } = useAuth();
  const selectRef = useRef<HTMLDivElement>(null);
  const getLanguageName = (languageCode: string): string => {
    const language = languages.find(lang => lang.LanguageCode === languageCode);
    return language ? language.Language : "Unknown";
  };
  const ORGANIZATION_ID = userDetails?.OrganizationId; 
  useEffect(() => {
    fetchLanguages();
  }, []);
  const ToLanguageNew=getLanguageName(toLanguage)
  const [guides, setGuides] = useState<any[]>([]); 
  const [uniqueLabels, setUniqueLabels] = useState<string[]>([]); 
  const extractUniqueGuides = (guides: any) => {
    const uniqueLabels: string[] = [];
  
    guides.forEach((guide: any) => {
      const { Name, GuideType } = guide; 
  
      if (GuideType === type) {
        if (!uniqueLabels.includes(Name)) {
          uniqueLabels.push(Name);
        }
      }
    });
  
    
    return uniqueLabels;
  }
 

  
  const [labelsNew, setLabelsNew] = useState<any>({});
  const isMobile = false; 

  
  useEffect(() => {
    const unsubscribe = subscribe(setSidebarOpen);
    return () => unsubscribe();
  }, []);



  const fetchLanguages = async () => {
    try {
      const dataNew = await getLanguages(); 
      setLanguages(dataNew);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dialogWidth = 1000;
  const dialogHeight = 500 + languages.length * 40;
  const handleClickOpen = () => {
    setOpen(true);
    setNewLanguagesAdded(false);

  };

  const handleClose = () => {
    setIsDropdownVisible(false);
    setOpen(false);
    setSelectedLanguages([]);
  };
  const [languagesList, setLanguagesList] = useState<language[]>(availableLanguages);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedLanguagesNew, setSelectedLanguagesNew] = useState<string[]>([]);

  const [triggerAdd, setTriggerAdd] = useState(false);

  const handleAddLanguage = (selectedLanguageCodes: string[]) => {
    const newLanguages = selectedLanguageCodes.filter(
      (code) => !languagesList.find((lang) => lang.code === code)
    ).map((code, index) => ({
      id: languagesList.length + index + 1,
      name: availableLanguages.find(lang => lang.code === code)?.name ?? code,
      code: code,
    }));

    setLanguagesList([...languagesList, ...newLanguages]);
    setIsDropdownVisible(false);
  };
  const [newLanguagesAdded, setNewLanguagesAdded] = useState(false);
  const handleLanguageChange = (event: any) => {
    const { target: { value } } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    const validSelectedValues = selectedValues.filter((code: string) =>
      availableLanguages.some(lang => lang.code === code)
    );
    const newLanguages = validSelectedValues.filter((code: string) =>
      !organizationLanguages.some(orgLang => orgLang.LanguageId === availableLanguages.find(l => l.code === code)?.id.toString())
    );

    const duplicateLanguages = validSelectedValues.filter((code: string) =>
      organizationLanguages.some(orgLang => orgLang.LanguageId === availableLanguages.find(l => l.code === code)?.id.toString())
    );
  
    const newLanguagesMapped = newLanguages.map((code: string) => {
      const language = availableLanguages.find(lang => lang.code === code);
      return language ? { Id: Date.now().toString(), LanguageId: language.id.toString(), Language: language.name } : null;
    }).filter(Boolean) as OrganizationLanguage[];
  

    if (newLanguagesMapped.length > 0) {
      setOrganizationLanguages(prevLanguages => [...prevLanguages, ...newLanguagesMapped]);

      localStorage.setItem('organizationLanguages', JSON.stringify([...organizationLanguages, ...newLanguagesMapped]));
    }
  
    const updatedSelectedLanguages = validSelectedValues.filter((code: string) =>
      !duplicateLanguages.includes(code)
    );
 
    const combinedLanguages = [...selectedLanguages, ...updatedSelectedLanguages];
  

    const finalSelectedLanguages = combinedLanguages.filter((lang, index) =>
      combinedLanguages.indexOf(lang) === index
    );

    setSelectedLanguages(finalSelectedLanguages);

    setNewLanguagesAdded(newLanguagesMapped.length > 0);
  };
  useEffect(() => {
    if (triggerAdd) {
      handleAddLanguage(selectedLanguages);
      setTriggerAdd(false);
    }
  }, [triggerAdd, selectedLanguages]);


  const handleNavigationOrButtonClick = () => {
    setTriggerAdd(true); 
  };
 
  const handleToLanguageChange = async (e: any) => {
    const newToLanguage = e.target.value as string;
    setToLanguage(newToLanguage);
  };

 
  const handleShowDropdown = () => {
    setIsDropdownVisible(true);
  };


  const handleEditLabel = (key:any,value:any) => {
    setEditLabelKey(key);
    setEditLabelValue(value);
  };
  const [updatedLabel, setUpdatedLabel] = useState<any>(null);
  const [updatedLabels, setUpdatedLabels] = useState<any[]>([]); 

  const handleCancelEdit = () => {
    setEditLabelKey(null);
    setEditLabelValue(""); 
  };


  const [searchTerm, setSearchTerm] = useState<string>(''); 
  const handleSearchChange = (e: any) => {
      setSearchTerm(e.target.value);
      };
      const filterLabels = (
        guideTypeOptions: Array<{
          ArtifactType: string;
          Id: string;
          LabelName: string;
          Value: string | null;
          LabelId: string;
        }>,
        term: string,
        selectedArtifactType: string
      ) => {
        if (!term && !selectedArtifactType) return guideTypeOptions;
      
        const lowercasedTerm = term.toLowerCase();
      
        return guideTypeOptions.filter(
          (label) =>
            label.ArtifactType === selectedArtifactType && 
            (label.LabelName.toLowerCase().includes(lowercasedTerm) ||
              (label.Value && label.Value.toLowerCase().includes(lowercasedTerm)))
        );
      };

        const [isLabelUpdated, setIsLabelUpdated] = useState(false);
        const handleSaveLabel = (key: string, labelId: string) => {
          setGuideTypeOptions((prevLabels) => {
            const updatedLabels = prevLabels.map((label) => {
              if (label.LabelId === labelId && label.LabelName === key) {
                if (label.Value !== editLabelValue) {
                  setIsLabelUpdated(true); 
                }
                return { ...label, Value: editLabelValue };
              }
              return label; 
            });
        
            const labelToUpdate = updatedLabels.find(
              (label) => label.LabelId === labelId && label.LabelName === key
            );

            setUpdatedLabels((prevUpdatedLabels) => {
              const isAlreadyUpdated = prevUpdatedLabels.some(
                (label) => label.LabelId === labelId && label.LabelName === key
              );
        
              if (isAlreadyUpdated) {

                return prevUpdatedLabels.map((label) =>
                  label.LabelId === labelId && label.LabelName === key
                    ? { ...label, Value: editLabelValue }
                    : label
                );
              } else {

                return [...prevUpdatedLabels, labelToUpdate];
              }
            });
        
            setUpdatedLabel(labelToUpdate); 
        
            return updatedLabels;
          });
          setEditLabelKey(null);
          setEditLabelValue("");
        };
        
        const [hoveredLabel, setHoveredLabel] = useState<string | null>(null)
  
        const renderLabels = (
          guideTypeOptions: Array<{
            ArtifactType: string;
            Id: string;
            LabelName: string;
            Value: string | null;
            LabelId: string;
          }> = [],
          searchTerm: string,
          selectedArtifactType: string
        ) => {
          if (!Array.isArray(guideTypeOptions)) {
            return null;
          }
       
          const filteredLabels = filterLabels(guideTypeOptions, searchTerm, selectedArtifactType);
          if (filteredLabels.length === 0) {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body1" color="textSecondary">
                  No Labels Found
                </Typography>
              </Box>
            );
          }
          return (
            <ul>
              {filteredLabels.map((label) => (
                <MenuItem key={label.LabelId}
                  style={{
                  minHeight: '48px', paddingTop: '0px'}}
                  onMouseEnter={() => setHoveredLabel(label.LabelId)} 
                onMouseLeave={() => setHoveredLabel(null)} >
                  {editLabelKey === label.LabelName ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        value={editLabelValue} 
                        onChange={(e) => setEditLabelValue(e.target.value)} 
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <IconButton onClick={() => handleSaveLabel(label.LabelName, label.LabelId)}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton onClick={handleCancelEdit}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography>{label.Value || ''}</Typography>
                    {hoveredLabel === label.LabelId && ( 
                      <IconButton
                        onClick={() => handleEditLabel(label.LabelName, label.Value)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                  )}
                </MenuItem>
              ))}
            </ul>
          );
          
        };
        const renderLabelsKeys = (
          guideTypeOptions: Array<{
            ArtifactType: string;
            Id: string;
            LabelName: string;
            Value: string | null;
            LabelId: string;
          }> = [],
          searchTerm: string,
          selectedArtifactType: string
        ) => {
          if (!Array.isArray(guideTypeOptions)) {
            return null;
          }
        
          const filteredLabels = filterLabels(guideTypeOptions, searchTerm, selectedArtifactType);
          if (filteredLabels.length === 0) {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body1" color="textSecondary">
                  No Labels Found
                </Typography>
              </Box>
            );
          }
          return (
            <ul>
              {filteredLabels.map((label) => (
                
                <MenuItem key={label.LabelId}  style = { { minHeight : '48px',paddingTop:'0px'}}> {/* Use Id as the key */}
               
                  {label.LabelName}
                </MenuItem>
              ))}
            </ul>
          );
        };
        
        
  const filteredLabels = filterLabels(guideTypeOptions, searchTerm,type);
 
 const ITEM_HEIGHT = 48;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
       width: 250,
     },
   },
  };


  const [filteredGuides, setFilteredGuides] = useState<any[]>([]); 

          const [showDropdown, setShowDropdown] = useState(true);


useEffect(() => {
  const fetchOrganizationLanguages = async () => {
    try {

      const allLanguages = await getOrganizationLanguages();
      const organizationLanguages = allLanguages.filter((lang:any) => lang.OrganizationId === ORGANIZATION_ID);
      const selectedLanguageCodes = organizationLanguages.map((lang:any) => lang.LanguageCode);

      if (selectedLanguageCodes.length > 0) {
        setSelectedLanguages(selectedLanguageCodes); 
      } else {
        setSelectedLanguages([]); 
      }
    } catch (error) {
    }
  };


  fetchOrganizationLanguages();
}, [ORGANIZATION_ID]);  
  
  
useEffect(() => {

  if (userDetails && userDetails.OrganizationId) {
    const fetchLanguagesByOrganization = async () => {
      try {

        const response = await getLanguagesByOrganization(userDetails.OrganizationId);

        setOrganizationLanguages(response.data);
        if (response.data.length > 0) {
          setToLanguage(response.data[0].Language);
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchLanguagesByOrganization();
  }
}, [userDetails]); 




const handleSaveLanguages = async () => {
  if (updatedLabels.length === 0) {
    return;
  }

  const formattedLabels = updatedLabels.map((label) => ({
    Id: label.LabelId || "",
    LabelName: label.LabelName,
    Module: "",  
    SecondaryLanguageLable: label.Value,  
  }));

  try {
    const response = await saveLanguageExpertiseLabels(toLanguage, formattedLabels);
    if (response.Success === true) {
      openSnackbar(response.SuccessMessage, "success");
      setIsLabelUpdated(false);
      setUpdatedLabels([]); 
    } else {
      openSnackbar(response.statusText, "error");
    }
  } catch (error) {

  }
};

const handleSave = async () => {
  try {
    const orgLanguages = selectedLanguages.map((code:any) => {
      const language = availableLanguages.find((lang) => lang.code === code);
      return {

        OrganizationId: ORGANIZATION_ID,
        LanguageId: language?.id?.toString() ?? '', 
        Language: language?.name ?? '', 
      };
    }).filter((lang:any) => lang.LanguageId && lang.Language); 
    

    if (orgLanguages.length > 0) {
      const result = await saveOrganizationLanguages(orgLanguages);

      const response = await getLanguagesByOrganization(ORGANIZATION_ID);

      setOrganizationLanguages(response.data);
      if (response.data.length > 0) {
        setToLanguage(response.data[0].Language);
      }

      localStorage.setItem('organizationLanguages', JSON.stringify(orgLanguages)); 
      handleClose();
      if (response.data.length > 0) {
        openSnackbar("Secondary Language Updated Successfully", "success");
      }
      
    } else {
    }
  } catch (error) {
  }
    handleClose();
};


  


  letorgLanguages = organizationLanguages;
 
  const getOrganizationLanguages = () => {
    const storedLanguages = localStorage.getItem('organizationLanguages');
    return storedLanguages ? JSON.parse(storedLanguages) : [];
  };
  const handleDeleteLanguage = async (language: string,organizationId:any) => {

    const languageToDelete = organizationLanguages.find(orgLang => orgLang.Language === language);

    if (!languageToDelete) {
      return;
    }

    const languageId = languageToDelete.Id; 
    const organizationIdLanguageToDelete = organizationLanguages.find(orgLang => orgLang.OrganizationId === organizationId);
    const ORG_ID = organizationIdLanguageToDelete?.OrganizationId;
    try {
      const response = await deleteOrganizationLanguage(languageId,ORG_ID)
      if (response.data.Success) {
        setOrganizationLanguages((prevOrganizationLanguages) =>
          prevOrganizationLanguages.filter((orgLang) => orgLang.Id !== languageId)
        );
  
        setSelectedLanguages((prevSelectedLanguages) =>
          prevSelectedLanguages.filter((code) => code !== languageToDelete.LanguageId)
        );
      } else {

      }
    } catch (error) {
      
    }
  };
  const [artifactLabels, setArtifactLabels] = useState([]);
  const [labelsTwo, setLabelsTwo] = useState<any>({});
  const [ArtifactlabelsNew, setArtifactLabelsNew] = useState<any>({});
 const [selectedGuideType, setSelectedGuideType] = useState<string>(''); 
 const [filteredLabelNames, setFilteredLabelNames] = useState<string[]>([]);

  const [mappedGuides, setMappedGuides] = useState<any[]>([]);
  const [mappedGuidesNew, setMappedGuidesNew] = useState<any[]>([]);
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    const fetchArtifactLabels = async () => {
      setLoading(true);
      try {

        const labels = await getArtifactLabels(toLanguage);
  
        const labelNames = labels.map((item: any) => ({
          Id: item.Id || "", 
          LabelName: item.LabelName,
          Value: item[toLanguage], 
        }));
  
        setLabelsNew(labelNames); 

        const ArtifactLabelsNames = labels.map((item: any) => ({
          LabelId: item.Id || "",
          ArtifactId: item.ArtifactId || "", 
          LabelName: item.LabelName,
          Value: item[toLanguage],
        }));
  
        setArtifactLabelsNew(ArtifactLabelsNames);
        const guides = await getAllGuides(-1, -1,filters,"");
  
      
        
        const mappedGuides = ArtifactLabelsNames.map((artifact: any) => {

          const matchingGuide = guides.find(
            (guide: any) => String(guide.GuideId) === String(artifact.ArtifactId)
          );
        
          if (matchingGuide) {
            const matchingFields: any = {};
            if (artifact.LabelName === matchingGuide.Name) {
              matchingFields.Name = matchingGuide.Name;
            }
        
            if (artifact.LabelName === matchingGuide.Content) {
              matchingFields.Content = matchingGuide.Content;
            }
        
            if (artifact.LabelName === matchingGuide.GuideStep?.[0]?.TextFieldProperties?.Text) {
              matchingFields.Text = matchingGuide.GuideStep[0]?.TextFieldProperties?.Text || "No text";
            }
        
            return {
              ...artifact,
              ArtifactType: matchingGuide.GuideType,
              ...matchingFields, 
            };
          } else {
            
            return artifact; 
          }
        });
        

        const guideTypeOptionsData = mappedGuides
  .filter((item: any) => 
    (item.LabelName.includes("Content") || item.LabelName.includes("Text"))
  )
  .map((item: any) => ({
    ArtifactType: item.ArtifactType, 
    LabelName: item.LabelName,        
    Value: item.Value,                
    Id: item.ArtifactId,   
    LabelId:item.LabelId,           
  }));

        setGuideTypeOptions(guideTypeOptionsData);

        const guideTypeOptionsDataNew = mappedGuides
        .map((item: any) => ({
          ArtifactType: item.ArtifactType, 
          LabelName: item.LabelName,       
          Value: item.Value,               
          Id: item.ArtifactId,
          LabelId:item.LabelId,
        }));
        const filteredMappedGuides = mappedGuides.filter((item: any) => Object.keys(item).length > 0);
          setMappedGuides(filteredMappedGuides);
  

        if (selectedData.length === 0) {
          const filteredGuideTypeOptionsByAge = guideTypeOptionsDataNew.filter(
            (item: any) => item.ArtifactType === type
          );
          setGuideTypeOptions(filteredGuideTypeOptionsByAge);
        }
        else {
         
          const filteredGuidesByType = filteredMappedGuides.filter(
            (guide: any) => guide.ArtifactType === type
          );
  
          const selectedIds = filteredGuidesByType
            .filter((guide: any) => selectedData.includes(guide.Name)) 
            .map((guide: any) => guide.ArtifactId); 
  

          setSelectedArtifactIds(selectedIds);
  

          const filteredGuideTypeOptionsData = filteredGuidesByType.filter((item: any) => {

            const isArtifactIdMatched = selectedIds.includes(item.ArtifactId);
            const hasContentAndText = item.Content || item.Text;
    
            return isArtifactIdMatched && hasContentAndText;
          });

          setGuideTypeOptions(filteredGuideTypeOptionsData);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
  
    if (toLanguage && type) {
      fetchArtifactLabels(); 
    }
  }, [toLanguage, type, selectedData]);
  
 
  useEffect(() => {
    
      const filteredLabels = mappedGuides
        .filter((item) => item.ArtifactType === type) 
        .map((item) => item.Name) 
        .filter(Boolean); 
      setUniqueLabels([...filteredLabels]);

  }, [type, mappedGuides]);


  const [isSaving, setIsSaving] = useState(false);
 
  return (
     <div className="qadpt-midpart setng-box">
      <div className="qadpt-content-block qadpt-multilingual">
      <div className="qadpt-head">
						<div className="qadpt-title-sec">
            <div className="qadpt-title">{t('Language Translation')}</div>
            <div className="qadpt-description">{t('select the secondary language to modify')}</div>
							
          </div>
          <div className="qadpt-right-part">
							<button
								onClick={handleClickOpen}
								className="qadpt-memberButton"
							>
								<i className="fal fa-add-plus"></i>
                                <span>{t('Manage Languages')}</span>
            </button>
            <button
              className={`qadpt-memberButton ${isLabelUpdated ? 'qadpt-mem-updated' : 'qadpt-disabled'}`}  onClick={handleSaveLanguages}
  disabled={!isLabelUpdated} >
  {t('Save')}
          </button>
						</div>
          </div>
          <div className="qadpt-filters">
  <div className="qadpt-filter-left">
    <FormControl className="qadpt-select-form" fullWidth>
      <Select
        ref={selectRef}
        labelId="demo-simple-select-readonly-label"
        id="demo-simple-select-readonly"
        defaultValue="Announcements"
        onChange={handleTypeChange}
        value={type}
      >
        <MenuItem value={"Announcements"}>Announcements</MenuItem>
        <MenuItem value={"Banners"}>Banners</MenuItem>
        <MenuItem value={"Tours"}>Tours</MenuItem>
        <MenuItem value={"Tooltips"}>Tooltips</MenuItem>
        <MenuItem value={"Checklists"}>Checklists</MenuItem>
        <MenuItem value={"Surveys"}>Surveys</MenuItem>
      </Select>
    </FormControl>

    <FormControl className="qadpt-select-form">
      <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedData}
        onChange={languageChange}
        input={<OutlinedInput label="Select" />}
        renderValue={() => null}
        MenuProps={MenuProps}
      >
        {uniqueLabels.filter(Boolean).map((label, index) => (
          <MenuItem key={index} value={label}>
            <Checkbox checked={selectedData.includes(label)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>

  <div className="qadpt-filter-right">
    <Box sx={{ padding: 1 }}>
      <TextField
        variant="outlined"
        placeholder={t('Search')}
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search sx={{ color: 'black' }} />
            </InputAdornment>
          ),
          inputProps: {
            sx: {
              fontSize: '0.875rem',
            },
          },
        }}
      />
    </Box>
  </div>
</div>


            
     

        <div className="qadpt-language-selection">
          <div className="qadpt-headers">
          <FormControl variant="standard" className="qadpt-lang-left" fullWidth>
        <InputLabel id="demo-simple-select-readonly-label"></InputLabel>
        <Select
          ref={selectRef}
          labelId="demo-simple-select-readonly-label"
          id="demo-simple-select-readonly"
          value={dropdownLanguage}
          inputProps={{ readOnly: true }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Telugu</MenuItem>
          <MenuItem value={20}>Hindi</MenuItem>
          <MenuItem value={30}>English</MenuItem>
        </Select>
            </FormControl>
            <FormControl className="qadpt-lang-rgt"fullWidth>
        <InputLabel id="demo-simple-select-label">Select Language to Modify</InputLabel>
        <Select
          ref={selectRef}
          labelId="demo-simple-select-label"
          id="demo-simple-selects"
          defaultValue={toLanguage}
          value={toLanguage}
          label="select language to modify"
          onChange={handleToLanguageChange}
          startAdornment={
            <InputAdornment position="start">
              <IconButton>
                <LanguageIcon sx={{ color: 'black' }} />
              </IconButton>
            </InputAdornment>
          }
        >
         {organizationLanguages.map((lang) => (
      <MenuItem key={lang.LanguageId} value={lang.Language}>
        {languageDisplayMap[lang.Language] || lang.Language}
      </MenuItem>
    ))}
        </Select>
      </FormControl>

          </div>
  <div className="qadpt-language-section">
    <Box>
              <div
               className="qadpt-sec-left"
              >
          {toLanguage && renderLabelsKeys(filteredLabels, searchTerm, type)}
        </div>
      {/* </FormControl> */}
            </Box>
            <Box>
              <div
                 className="qadpt-sec-right"
              >
          {toLanguage && renderLabels(filteredLabels, searchTerm, type)}
        </div>
      {/* </FormControl> */}
    </Box>
  </div>

  
</div>


      <div>
          <Dialog open={open} onClose={handleClose} className="qadpt-mngpwd-popup">
          <DialogTitle>Manage Languages</DialogTitle>
            <DialogContent>
            <div className="qadpt-primary-language-row">
        <TextField
          className="qadpt-primary-lang"
          variant="standard"
          fullWidth
          label="Primary Language"
          value="English"
          InputProps={{
            readOnly: true,
          }}
        />
        <Button className="qadpt-add-button" variant="contained"  onClick={handleShowDropdown}>
          + Add
        </Button>
      </div>
               {isDropdownVisible ? (
     <Box display="flex" alignItems="center" mt={2}>
     <div className="qadpt-lang-dropdown">
       <div >
       <FormControl>
        <InputLabel id="select-language-label">Select Language</InputLabel>
<Select
  labelId="select-language-label"
  id="select-language"
  multiple
  value={selectedLanguages}
  onChange={handleLanguageChange}
  input={<OutlinedInput label="Select Language" />}
  renderValue={(selected) => {
    const selectedList = selected as string[];

    if (selectedList.length === 0) {
      return ""; 
    }

    return selectedList
      .map((code) => {
        const language = availableLanguages.find((lang) => lang.code === code);
        return language ? language.name : code;
      })
      .join(" "); 
  }}
>
  {availableLanguages.map((lang) => (
    <MenuItem key={lang.code} value={lang.code}>
      <Checkbox checked={selectedLanguages.indexOf(lang.code) > -1} />
      <ListItemText primary={lang.name} />
    </MenuItem>
  ))}
</Select>


      </FormControl>
       </div>
                </div>
                
                
   </Box>
       ) : (
        null
            )}
            
            {/* {selectedLanguages.length > 0 && ( */}
            <Box mt={2}>
  <div className="qadpt-lang-subhead">Secondary Language(s)</div> {/* Heading */}
  {organizationLanguages.map((language, index) => (
    <Box key={language.LanguageId} display="flex" alignItems="center" sx={{ mb: 2 }}>
      <TextField
        className="qadpt-second-lang"
        variant="standard"
        fullWidth
        label={`Secondary Language ${index + 1}`} 
        value={language.Language}
        InputProps={{
          readOnly: true,
        }}
        sx={{ mr: 1 }}
      />
      <IconButton onClick={() => handleDeleteLanguage(language.Language,language.OrganizationId)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  ))}
</Box>

      {/* )} */}
        </DialogContent>
          <DialogActions>
              <Button onClick={handleSave} disabled={!newLanguagesAdded} 
                className={`qadpt-langsave ${newLanguagesAdded ? 'qadpt-langsave-active' : 'qadpt-langsave-inactive'}`} >
            Save
          </Button>
              <Button onClick={handleClose}  className="qadpt-langclose">
               
            Close
          </Button>
        </DialogActions>

        </Dialog>
          </div>
    
      </div>
      </div>
  );
}

export default Translater;
export {letorgLanguages}