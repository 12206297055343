import JSEncrypt from 'jsencrypt';
import { adminApiService, idsApiService } from "./APIService";

export const superAdminLogin = async (
  emailId: string,
  password: string,
  organizationId: string,
  rememberLogin: boolean,
  returnUrl: string = ""
): Promise<any> => {
  try {

    const requestUrl = `/AdminLogin`;
    const requestBody = {
      Email: emailId,
      Password: password,
      RememberLogin: rememberLogin,
      ReturnUrl: returnUrl,
    };

    const response = await idsApiService.post(requestUrl, requestBody);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data.ErrorMessage;
    }
  } catch (error) {
    throw error; 
  }
};
