import React, { useState, useEffect } from "react";
import { useAuth } from "../auth/AuthProvider";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import userManager from "../auth/UseAuth";
import { GetUserDetails, GetUserDetailsById } from "../../services/UserService";
import { getOrganizationById } from "../../services/OrganizationService";
import { User } from "../../models/User";
import { Organization } from "../../models/Organization";
import jwt_decode from "jwt-decode";
import { LoginUserInfo } from "../../models/LoginUserInfo";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

let OrganizationId: string;
let UserId: string;

const Home: React.FC = () => {
	const { user, userDetails } = useAuth();
	const [loginUserDetails, setUserDetails] = useState<User | null>(null);
	const [organizationDetails, setOrganizationDetails] = useState<Organization | null>(null);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [userId, setUserId] = useState(String);
	const [organizationId] = useState(String);
	const [loginUserInfo, setLoginUserInfo] = useState<LoginUserInfo | undefined>(undefined);
	const location = useLocation();
	const Navigate = useNavigate();


	const fetchOrganization = async (loggedinUserInfo: LoginUserInfo) => {
		setLoginUserInfo(loggedinUserInfo);
		var orgDetails = await getOrganizationById(loggedinUserInfo.OrganizationId);
		setOrganizationDetails(orgDetails);
		if (!userDetails) {
			setUserDetails(userDetails);
		}
		else {
			const userResponse = await GetUserDetails();
			setUserDetails(userResponse?.data ?? null);
			localStorage.setItem("userType", userResponse?.data?.UserType ?? "");	
		}
		UserId = loggedinUserInfo.UserId;
		OrganizationId = orgDetails.OrganizationId;
	};


	useEffect(() => {
		const token = user?.access_token || localStorage.getItem("userInfo");
		const lastpath = localStorage.getItem("last_path") ;

		if (token) {
			try {				
				if (userDetails?.UserType === "SuperAdmin") {
					Navigate("/superadmin/organizations");
				}				
				const loggedinUserInfo = jwt_decode<LoginUserInfo>(token);
				fetchOrganization(loggedinUserInfo);
				if (lastpath && lastpath!="" )
				{
					localStorage.setItem("last_path","");
					Navigate(lastpath);
				}
			} catch (error) {

				userManager.signoutRedirect();
			}
		}
		else {
			userManager.signoutRedirect();
		}
	}, [userDetails]);
const{t:translate}=useTranslation();
	return (
		<div
			className={`smooth-transition`}
			style={{ marginLeft: sidebarOpen ? "250px" : "" }}
		>
			<h1 style={{ textAlign: "center" }}>{translate('Home Page')}</h1>
			{userDetails && (
				<div>
					<p style={{ textAlign: "center" }}>Welcome, {userDetails.FirstName}</p>
				</div>
			)}
		</div>
	);
};

export default Home;
export { OrganizationId, UserId };
