import React, { useState, useEffect, ChangeEvent, FocusEvent, FormEvent } from "react";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
} from "@mui/material";
import { BlockUser } from "../../services/UserService";
import { adminUrl } from "../../services/APIService";
import { useSnackbar } from "../../SnackbarContext";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { JSEncrypt } from "jsencrypt";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styles from "./UserStyles.module.scss";
import { useAuth } from "../auth/AuthProvider";

type InputFields = {
	Password: string;
};

type ErrorFields = Partial<InputFields>;

const Userblock = (props: any) => {
	const {
		blockUserPopup,
		setBlockUserPopup,
		userId,
		fetchUsersList,
		setModels,
		setLoading,
		skip,
		top,
		//Organizationid,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { openSnackbar } = useSnackbar();
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		setLoading(true);
		try {
			await BlockUser(
				setModels,
				setLoading,
				OrganizationId,
				skip,
				top,
				setTotalcount,
				userId,
				openSnackbar,
				orderByFields,
				filters
			);

			setLoading(false);
			setBlockUserPopup(false);
		} catch (error) {
		}
	};

	return (
		blockUserPopup && (
			<div className="qadpt-usrconfirm-popup qadpt-danger">
  			<div>
    <div className="qadpt-icon">
      <LockOutlinedIcon />
    </div>
    <div className="qadpt-popup-title">Block user</div>
  </div>

  <div className="qadpt-warning">
    Blocking this user will remove all his access from this platform.
  </div>

  <div className="qadpt-buttons">
    <button
      onClick={() => setBlockUserPopup(false)}
      className="qadpt-cancel-button"
    >
      Cancel
    </button>

    <button
      className="qadpt-conform-button"
      type="submit"
    >
      Block
    </button>
  </div>
</div>

		)
	);
};

export default Userblock;
