import { adminApiService, userApiService } from "./APIService";
import { User ,Filter} from '../models/UserRole';

export const getUserRoles = async () => {
    try {
        const response = await adminApiService.get(`/User/GetAllRoles`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user roles", error);
        throw error;
    }
};
export const GetOrganizationUsers = async (payload: {
    organizationId: string;
    skip: number;
    top: number;
    orderByFields: string;
    filters: any[];
}): Promise<User[]> => {
    try {
        const response = await adminApiService.post('/User/GetOrganizationUsers', payload);
        return response.data.results;
    } catch (error) {
        console.error("Error fetching organization users", error);
        throw error;
    }
};


export const saveUserRoles = async (data: any, openSnackbar:any) => {
    try {
        const response = await adminApiService.post('User/SaveUserRole', data);
        const responseData = response.data;
        
        if (responseData.Success) {
             openSnackbar(responseData.SuccessMessage, "success");
            return responseData.success;  
        } else {
            openSnackbar(responseData.ErrorMessage, "error");
            // throw new Error('Operation was not successful');
        }
        
    } catch (error) {
        throw new Error('Failed to save user roles and emails: ' + error);
    }
};
export const GetAllUserRolesbyOrganizationId = async (organizationId:any,skip:number,top:number,orderByFields:any,filters:Filter[],setTotalcount:any) => {
    try {
        const requestBody = {
			skip,
			top,
			filters: filters, 
			orderByFields: orderByFields, 
			
		};
            const response = await adminApiService.post(`/User/GetUserRolesbyOrganizationId`, requestBody);
        setTotalcount(response.data.Count);
        return response.data.results;
    } catch (error) {
        console.error("Error fetching user roles", error);
        throw error;
    }
};

export const GetAllUserRolesbyAccountId = async (accountId: any, top: number, skip: number, orderByFields: string, filters: Filter[], setTotalcount: any) => {
    try {
        const requestBody = {
			skip,
			top,
			orderByFields: orderByFields, 
			filters:filters, 
			accountId: accountId,
		};
            const response = await adminApiService.post(`/User/GetUserRolesbyAccountId`, requestBody);
        setTotalcount(response.data.Count);
        return response.data.results;
        
    } catch (error) {
        console.error("Error fetching user roles", error);
        throw error;
    }
};


export const deleteUserRole = async (userRoleId:any,userId:any,openSnackbar:any) => {
    try {    
        const response = await adminApiService.delete('User/DeleteUserRole', {
            params: {
                userRoleId
            }
        });
        const responseData = response.data;    
        if (responseData.Success) {
             openSnackbar(responseData.SuccessMessage, "success");
            return responseData.success;  
        } else {
            openSnackbar(responseData.ErrorMessage, "error");
        }
        return response.data;
    } catch (error) {
        console.error("Error deleting user role", error);
        throw error;
    }
};





