import React, { useContext, useEffect, useState } from 'react';
import { Container, Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, DialogContentText, InputAdornment } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSpacingParams } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getAllGuides ,DeleteGuideByGuideId, GetGudeDetailsByGuideId } from '../../services/GuideService';

import settingsiconAnnouncements from "../../assets/icons/SettingsiconAnnouncements.svg";
// import SettingsPage from './SettingsPage';

import { useSnackbar } from '../../SnackbarContext';
import CreateNewPopup from '../common/CreateNewGuidePopup';
import { useAuth } from '../auth/AuthProvider';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { AccountContext } from '../account/AccountContext';
import CloneInteractionDialog from '../common/CloneGuidePopup';

interface Tour {
AccountId: string;
  Content: string;
  CreatedBy: string;
  CreatedDate: string;
  Frequency: string;
  GuideId: string;
  GuideStatus: string;
  GuideType: string;
  Name: string;
  OrganizationId: string;
  Segment: string;
  TargetUrl: string;
  TemplateId: string;
  UpdatedBy: string;
  UpdatedDate: string;
}

const Tours = () => {
  const [activeTab, setActiveTab] = useState(0); 
  const [tours, setTour] = useState<Tour[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const[name,setName]=useState("Tours")
  const { signOut, userDetails } = useAuth();
  const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId ?? "");
  const [totalcount, setTotalcount] = useState(0);
  const [orderByFields, setOrderByFields] = useState("");
  const [filters, setFilters] = useState([]);
  const [skip, setskip] = useState(0);
  const [top, settop] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { openSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [guideIdToDelete, setGuideIdToDelete] = useState<string | null>(null);
  const [GuidenametoDelete, setGuideNametoDelete] = useState('');
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
	const [cloneTourName, setCloneTourName] = useState<Tour | null>(null);
	const [tourName, setTourName] = useState<Tour | null>(null);
	const [searchQuery, setSearchQuery] = useState('');
  const { accountId } = useContext(AccountContext);
  



  const [showPopup, setShowPopup] = useState(false);
  const userInfo = localStorage.getItem('userInfo');
  const parsedInfo = JSON.parse(userInfo ? userInfo : '');
  const userData = JSON.parse(parsedInfo.user);
	const organizationId = userData.OrganizationId;
	const [loading, setLoading] = useState(false);


  const fetchTours = async () => {
    const { page, pageSize } = paginationModel;
    const offset = page * pageSize;
    const statusFilter = activeTab === 0 ? 'Active' : activeTab === 1 ? 'InActive' : 'Draft';
    const filters = [
      {
        FieldName: 'GuideType',
        ElementType: 'string',
        Condition: 'equals',
        Value: 'Tours',
        IsCustomField: false,
      },
      {
        FieldName: 'GuideStatus',
        ElementType: 'string',
        Condition: 'equals',
        Value: statusFilter,
        IsCustomField: false,
		},
		{
			FieldName: 'Name', 
			ElementType: 'string',
			Condition: 'contains',
			Value: searchQuery,
			IsCustomField: false,
      },
      {
        FieldName: 'AccountId', 
        ElementType: 'string',
        Condition: 'contains',
        Value: accountId,
        IsCustomField: false,
        },
    ];
    const orderByFields = '';
    const data = await getAllGuides(offset, pageSize, filters,orderByFields);
    setTour(data.results);
    setTotalCount(data._count);  
	};
	const changeTabToDraft = () => {
		setActiveTab(3);
	  };
  const handleSearch = () => {
	fetchTours(); 
  };
 
  const handleKeyDown = (event: React.KeyboardEvent) => {
	if (event.key === 'Enter') {
	  handleSearch(); 
	}
	};
	const handleEditClick = (guide: Tour) => {
		const targetUrl = guide?.TargetUrl; 
		if (targetUrl) {
		  window.open(targetUrl, '_blank');
		} 
	   
	  };

	useEffect(() => {
		if (accountId) {
			fetchTours();
		}
  }, [paginationModel, activeTab,accountId]);

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  };

  const handleDelete = async () => {
    if (guideIdToDelete) {
      try {
        const response = await DeleteGuideByGuideId(guideIdToDelete);
        if (response.Success) {
          openSnackbar(`${GuidenametoDelete} deleted Successfully`, "success");
          await fetchTours();  
        } else {
          openSnackbar(response.ErrorMessage, "error");
        }
      } catch (error) {
       
      }
    }
    setOpenDialog(false);
    setGuideIdToDelete(null);
    setGuideNametoDelete("");
  };
  const handleDeleteConfirmation = (guideId: string) => {
    setGuideIdToDelete(guideId);
    setOpenDialog(true);
  };
  const handleCopyClick = (tour: Tour) => {
    setCloneTourName(tour);
    setIsCloneDialogOpen(true);
	};
	const navigate = useNavigate(); // Hook to get navigate function

	// const handleSettingsClick = (guideName: string) => {
    //     navigate('/settings-page', { state: { guideName } }); // Pass the guide name in the state
	// };
	// const handleSettingsClick = (guideData: any) => {
	// 	//<SettingsPage {...guideData={guideData}}/>
	// 	navigate('/settings-page', { state: { guideData } }); // Pass the complete guide object
  //   };

    const handleSettingsClick = async (params: any) => {
      const guideId = params.GuideId;
      const response = await GetGudeDetailsByGuideId(guideId);
      if (response) {  
        navigate('/settings-page', { state: { response } });
      } else {
        openSnackbar("GuideId Was empty", "error");
      }
    };
	
  const handleCloneSuccess = async () => {
    await fetchTours();
  };
  const openPopup = () => {
		setShowPopup(true);
	};
  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Name',
      width: 450,
      hideable: false,
    },
    {
      field: 'UpdatedDate',
      headerName: 'Last Edited',
      width: 250,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => (
        <span>{new Date(params.row.UpdatedDate).toLocaleDateString()}</span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Tooltip arrow title="Edit">
		  <IconButton onClick={() =>handleEditClick(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Clone">
            <IconButton onClick={() => handleCopyClick(params.row)}>
              <CopyAllOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Settings">
		  <IconButton onClick={() => handleSettingsClick(params.row)}>
		  <img src={settingsiconAnnouncements} alt="settings-announcemnets" />

            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete">
            <IconButton onClick={() => {
              handleDeleteConfirmation(params.row.GuideId);
              setGuideNametoDelete(params.row.Name);
            }}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  return (
    <Container maxWidth="xl">
        <div className='qadpt-web'>
        <div className='qadpt-webcontent'>
        <div className="qadpt-head">
							<div className="qadpt-title-sec">
								<div className="qadpt-title"> Tours </div>
							</div>
							<div className="qadpt-right-part">
								<button
									onClick={openPopup}
									className="qadpt-memberButton"
								>
									<i className="fal fa-add-plus"></i>
									<span>New Tour</span>
								</button>
							</div>
          </div>

          <div className="qadpt-tabs-container">
	<Tabs value={activeTab} onChange={handleTabChange}>
		<Tab label="Active" />
        <Tab label="InActive" />
		<Tab label="Draft" />
	</Tabs>
          </div>
		  <TextField
    variant="outlined"
    placeholder="Search Experiences"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            className='qadpt-websearch'
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon
            onClick={handleSearch}
            style={{ cursor: 'pointer' }}
          />
        </InputAdornment>
      ),
    }}
  />


      <div  className='qadpt-webgird'>
        <DataGrid
      
          rows={tours}
          columns={columns}
          getRowId={(row) => row.GuideId}
          getRowSpacing={getRowSpacing}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={totalCount}
          pageSizeOptions={[15, 25, 50, 100]}
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: 'Records Per Page',
            },
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            padding: '20px',
            borderRadius: '15px',
            maxWidth: '400px',
            textAlign: 'center',
          },
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <div
              style={{
                backgroundColor: '#FDECEA',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteOutlineOutlinedIcon sx={{ color: '#F44336', fontSize: '35px' }} />
            </div>
          </div>
          <div style={{ fontSize: '20px', fontWeight: 500, color: '#333' }}>
            Delete Tour
          </div>
        </DialogTitle>

        <DialogContent sx={{ padding: '10px 0' }}>
          <DialogContentText style={{ fontSize: '14px', color: '#9E9E9E' }}>
            The <span style={{ fontWeight: 'bold' }}>{GuidenametoDelete}</span> cannot be restored once it is deleted.
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', padding: '0 24px 20px' }}>
          <Button
            onClick={() => setOpenDialog(false)}
            sx={{
              fontWeight: 'bold',
              color: '#9E9E9E',
              border: '1px solid #9E9E9E',
              borderRadius: '8px',
              width: '100px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              backgroundColor: '#F44336',
              color: '#FFF',
              fontWeight: 'bold',
              marginLeft: '10px',
              borderRadius: '8px',
              width: '100px',
              '&:hover': {
                backgroundColor: '#D32F2F',
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {isCloneDialogOpen && cloneTourName && (
        <CloneInteractionDialog
              open={isCloneDialogOpen}
              handleClose={() => setIsCloneDialogOpen(false)}
              initialName={cloneTourName}
              onCloneSuccess={handleCloneSuccess}
              name={name}
        />
      )}
          {showPopup ? (
            <>
            <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
              zIndex: 999, // Below popup but above rest of content
            }}
          />

          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the popup
              zIndex: 1000, // Higher than the overlay
              backgroundColor: "white", // Popup background
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
           <CreateNewPopup
							 setTourslist={setTour}
               setShowPopup={setShowPopup}
               showPopup={showPopup}
               setTotalcount={setTotalCount}
               setActiveTab={setActiveTab}
               activeTab={activeTab}
               changeTab={changeTabToDraft}
               name={name}
						   />
          </div>
           </>
							 
						) : (
							""
          )}
        </div>
        </div>
    </Container>
  );
};

export default Tours;
