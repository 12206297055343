import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, Alert } from "@mui/material";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

type SnackbarContextType = {
	openSnackbar: (message: string, severity: "success" | "error") => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
	const context = useContext(SnackbarContext);
	if (!context) {
		throw new Error("useSnackbar must be used within a SnackbarProvider");
	}
	return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

	const openSnackbar = (message: string, severity: "success" | "error") => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		<SnackbarContext.Provider value={{ openSnackbar }}>
			{children}
			<Snackbar
				className={`qadpt-toaster ${snackbarSeverity === 'success' ? 'qadpt-toaster-success' : 'qadpt-toaster-error'}`}
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					className="qadpt-alert"
					icon={snackbarSeverity === "success" ? <CelebrationOutlinedIcon /> : <ErrorOutlineOutlinedIcon />} // Display icons conditionally
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
	
};
