import React, { useEffect, useState, useRef } from 'react';
import { isSidebarOpen } from '../adminMenu/sidemenustate';
import { blue, blueGrey } from '@mui/material/colors';
import { TextareaAutosize ,Box,Container} from "@mui/material";
import CopyToClipboard from 'react-copy-to-clipboard';
const CodeInstall: React.FC = () => {

  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [copied, setCopied] = useState(false);
  const scriptContent = `
    (function(g,u,i,d,e,s){
        g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];
        var k=u.createElement(i);k.async=true;
        k.src='https://static.userguiding.com/media/user-guiding-1.0.0-embedded.js';
        f.parentNode.insertBefore(k,f);
        if(g[d]) return;var ug=d;g[d]={
          q:[],c:function(n){
            return function(){
              g[d].q.push([n,arguments])
            }
          }
        };
        var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];
        for(var j=0;j<m.length;j++) {
          g[d][m[j]]=g[d].c(m[j])
        }
      })(window,document,'script','userGuiding','userGuidingLayer','131226793ID');
`;



  const copyCode = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);    
    
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = scriptContent;
    document.head.appendChild(script);    
    return () => {
      document.head.removeChild(script);
    };
  }, [scriptContent]);

  // Get script Dynamicaly from html  
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.value = scriptContent;
    }
  }, [scriptContent]);

  return (
    <Container>
    <div className="qadpt-midpart setng-box">
         <div className="qadpt-content-block">
         <div className="qadpt-head">
     <div className="qadpt-title-sec">
       <div className="qadpt-title">Default</div>
              <div className="qadpt-description">
                <div>
                  Copy and paste this code inside the script tag to the head section of every webpage,
                </div>
                <div>
                where you want to show the content to your users.
                </div>
                <div>
                Container Code
                </div>
              </div>
     </div>
     <div className="qadpt-right-part">
               
       </div>
     <div>
     </div>
    </div> 
        <Box className="qadpt-content-box">
              {/* <div className="qadpt-grd-head">						
                     <Box className="slt-acc-drp">                        
                     </Box>               
                     <div className="qadpt-usrfilter">                  
                     </div>
              </div>         */}
            <pre>
            <code className='qadpt-code-textarea'>
                 {scriptContent}
             </code>
             </pre>
             
             <CopyToClipboard  text={scriptContent} onCopy={copyCode}>
          <button
            className='copycodelabel'
            aria-disabled='true'>Copy the Code</button>
        </CopyToClipboard>
        {copied && <p className='copycodelabel'>Copied to clipboard!</p>}       
         
          </Box>
        </div>
        </div>
 </Container>
    // <div className={`smooth-transition`} style={{ marginLeft: sidebarOpen ? '250px' : '100'}}>
    //   <div style={{ marginLeft: -231 }} >
    //     <h2 className='invite-text' style={{ marginLeft: 42 }}>Default</h2>
    //     <h4 style={{ marginLeft: 42, color: 'grey' }} >Copy and paste this code inside the script tag to the head section of every webpage,</h4> 
    //     <h4 style={{ marginLeft: 42, color: 'grey',marginTop:-16 }} > where you want to show the content to your users.</h4>
    //     <h3 style={{ marginLeft: 42 }}>Container Code</h3>       
    //     <pre>
    //         <code className='qadpt-code-textarea'>
    //             {scriptContent}
    //         </code>
    //     </pre>
       
    //   </div>
    // </div>
  );
};

export default CodeInstall;
