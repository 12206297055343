import axios from "axios";
import { adminApiService } from "./APIService";
const adminUrl = process.env.REACT_APP_ADMIN_API


export const getLanguages = async () => {
    try {
      const response = await adminApiService.get("/Translation/GetLanguages");
      const apiData = response.data;
  
      if (Array.isArray(apiData)) {
        return apiData; 
      } else {
        console.error("Invalid data format from API:", apiData);
        return []; 
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      return []; 
    }
  };
  export const getPlatformLabels = async (language: string, module: string = '') => {
      const response = await adminApiService.get(`/Translation/GetPlatformLabels`, {
        params: {
          language,
          module,
        },
      });
      return response.data;
};
export const getLabels = async (language: string, isMobile: any = false) => {
  const response = await adminApiService.get(`/Translation/GetAllLabels`, {
    params: {
      language,
      isMobile,
    },
  });
  return response.data;
};
  export interface OrganizationLanguages {
    Id: string;
    OrganizationId: string |undefined;
    LanguageId: string;
    Language: string;
    LanguageExpertUserId: string;
    LanguageExpertMailId: string;
  }
  
  
export const saveOrganizationLanguages = async (organizationLanguages: { OrganizationId: string |undefined, LanguageId: string, Language: string }[]) => {
	try {
	  const response = await adminApiService.post(`/Translation/SaveOrganizationLanguages`, organizationLanguages);
	
		return response.data;
		
	} catch (error) {
	 
	  throw error;
	}
};
  

    
  
  interface PlatformLabel {
      Id: string;
      LabelName: string;
      Module: string;
      SecondaryLanguageLable: string;
    }
    

export const updatePlatformLabels = async (labels: PlatformLabel[], language: string) => {
      try {
        const response = await adminApiService.post('/UpdatePlatformLabels', { platFormLabels: labels, language });
        return response.data; 
      } catch (error) {

        throw error; 
      }
    };
    
    export const getArtifactLabels = async (language: string) => {
      try {

        const response = await adminApiService.get(`/Translation/GetArtifactLabels?language=${language}`);

        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        const data = response.data;
        return data; 
      } catch (error) {
        throw error;
      }
    };
    
    
  
    
    export const getLanguagesByOrganization = async (organizationId:any) => {
      try {
        const response = await adminApiService.get(`/Translation/GetLanguagesByOrganization`);
        return response;
      } catch (error) {
        throw error;
      }
    };

export const deleteOrganizationLanguage = async (languageId:any,organizationId:any) => {
  try {
    const response = await adminApiService.delete(`/Translation/DeleteOrganizationLanguages?id=${languageId}`);

    if (response.status !== 200) {
      throw new Error(`Failed to delete language with ID: ${languageId}`);
    }

    return response; 
  } catch (error) {
    throw error;
  }
};
export const saveLanguageExpertiseLabels = async (language: string, formattedLabels: any[]) => {
  try {
    if (!Array.isArray(formattedLabels)) {
      throw new Error("formattedLabels should be an array");
    }

    const response = await adminApiService.post(
      `/Translation/SaveLanguageExpertiseLabels?language=${language}`,
      formattedLabels,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

