import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CheckGuideNameExists, CopyGuide } from "../../services/GuideService";
import { useSnackbar } from "../../SnackbarContext";
interface Announcement {
    AccountId: string;
    Content: string;
    CreatedBy: string;
    CreatedDate: string;
    Frequency: string;
    GuideId: string;
    GuideStatus: string;
    GuideType: string;
    Name: string;
    OrganizationId: string;
    Segment: string;
    TargetUrl: string;
    TemplateId: string;
    UpdatedBy: string;
    UpdatedDate: string;
}

interface CloneInteractionDialogProps {
    open: boolean;
    handleClose: () => void;
    initialName: Announcement; 
    onCloneSuccess: () => void;
    name: string;
}

const CloneInteractionDialog: React.FC<CloneInteractionDialogProps> = ({ open, handleClose, initialName, onCloneSuccess,name }) => {
    const [announcementName, setAnnouncementName] = useState(`${initialName.Name}-Copy`);
    const [error, setError] = useState<string | null>(null);
    const { openSnackbar } = useSnackbar();

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setAnnouncementName(newValue);

        // Validate the length of the new value immediately while typing
        if (newValue.trim() === "") {
            setError("Name is required.");
        } else if (newValue.trim().length < 3) {
            setError("Guide Name must be at least 3 characters.");
        } else {
            setError(null); // Clear the error if the input is valid
        }
    };

    
    const handleClone = async () => {
        // Final validation before cloning
        if (error) {
            return; // Exit if there's an error
        }
        
        const guideName = announcementName; 
        const accountId = initialName.AccountId;
        const organizationId = initialName.OrganizationId;

        const existsResponse = await CheckGuideNameExists(guideName, accountId, name);
        
        if (existsResponse === true) {
            setError("A guide with this name already exists.");
        } else {
            const guideId = initialName.GuideId; 
            const guideStatus = initialName.GuideStatus; 
            const guideType = initialName.GuideType;
            const copyResponse = await CopyGuide(guideId, organizationId, announcementName, accountId, guideType);
            if (copyResponse.Success) {
                openSnackbar(`${announcementName} Copied Successfully. Please Check The Drafts`, "success");
                onCloneSuccess();
                handleClose();
            } else {
                openSnackbar(copyResponse.ErrorMessage, "error");
            }
        }
    };
    const paraGraph = `Clone ${initialName.Name}`
    return (
        <Dialog open={open} onClose={handleClose} className='qadpt-webclonepopup'>
            <DialogTitle className='qadpt-title'>{paraGraph}</DialogTitle>
            <IconButton 
                edge="end" 
                color="inherit" 
                onClick={handleClose} 
                aria-label="close" 
                className='qadpt-close'
            >
                <CloseIcon />
            </IconButton>
            <div className='qadpt-subtitle'>
                Enter the Name of New Announcement
            </div>
            <DialogContent>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={announcementName}
                    onChange={handleNameChange}
                    error={!!error}
                    helperText={error || ""}
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClone} variant="contained" color="primary">
                    Clone
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CloneInteractionDialog;
