import React, { Suspense, lazy,useEffect,useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import Layout from "./components/layout/Layout";
import { RtlProvider } from "./RtlContext";
import { SnackbarProvider } from "./SnackbarContext";
import Routing from "./routing/Routings";
import { AuthProvider } from "./components/auth/AuthProvider";
import { useLocation,useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {ResetPasswordLinkConsumed} from "./services/ExpirelinkService";
import { AccountProvider } from "./components/account/AccountContext";

function App() {
	const location = useLocation();
	//const passwordLogId = useParams();
	const navigate = useNavigate();
	//const history = useHistory();
	const [isResetLinkValid, setIsResetLinkValid] = useState(false);
	const [isCheckingLink, setIsCheckingLink] = useState(false);
	//const noLayoutRoutes = ["/login", "/forgotpassword", "/resetpassword/passwordLogId", "/admin/adminlogin"];

	const noLayoutRoutes = ["/login", "/forgotpassword", "/resetpassword/:passwordLogId", "/admin/adminlogin","/linkexpired"];

	const uuidRegex = "[0-9a-fA-F-]{36}";
	const isNoLayoutRoute = noLayoutRoutes.some(route => {
		if (route === "/resetpassword/:passwordLogId") {
			const resetPasswordRegex = new RegExp(`^/resetpassword/${uuidRegex}$`);
			return resetPasswordRegex.test(location.pathname);
		}
		return location.pathname === route;
	});
	const extractPasswordLogId = () => {
        const match = location.pathname.match(new RegExp(`/resetpassword/(${uuidRegex})`));
        return match ? match[1] : null;
    };

    const passwordLogId = extractPasswordLogId(); 
    const checkResetLinkConsumed = async (passwordLogId:any) => {
		try {
			setIsCheckingLink(true); 
			const response = await ResetPasswordLinkConsumed(passwordLogId);
			
			if (response === true) {
				const isConsumed = await response
				setIsResetLinkValid(!isConsumed); 
				if (isConsumed === true) {
					navigate("/linkexpired");
				}
			} else {
				navigate(`/resetpassword/${passwordLogId}`)
			}
		} catch (error) {
			navigate("/login")
		} finally {
			setIsCheckingLink(false); 
		}
	};

	
	useEffect(() => {
		if (location.pathname.includes("/resetpassword") && passwordLogId) {
			checkResetLinkConsumed(passwordLogId);
		}
	}, [passwordLogId]);

	if (isCheckingLink) {
		return <div>Loading...</div>; 
	}

	return (
		<AuthProvider>
			<AccountProvider>
			<SnackbarProvider>
				<Suspense fallback={<div>Loading...</div>}>
					<RtlProvider>
						{isNoLayoutRoute ? (
							<Routing />
						) : (
							<Layout>
								<Routing />
							</Layout>
						)}
					</RtlProvider>
				</Suspense>
				</SnackbarProvider>
				</AccountProvider>
		</AuthProvider>
	);
}

export default App;
