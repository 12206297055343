
import {adminApiService,userApiService} from "./APIService";
import { Organization } from "../models/Organization";
import { AnySoaRecord } from "dns";
import { useNavigate} from "react-router-dom";

// -------------------------------Organization Apis------------------------------


const adminUrl = process.env.REACT_APP_ADMIN_API
const userUrl = process.env.REACT_APP_USER_API

export const getOrganization = async (): Promise<Organization[]> => {
	try {
		const response = await adminApiService.get<Organization[]>("Organization/GetOrganizations");
		return response.data;
	} catch (error) {
		console.error("Error fetching organizations:", error);
		throw error;
	}
};

export const getAllOrganizations = async (setOrganizations: any, setLoading: any) => {
	try {
		const response = await adminApiService.get("/Organization/GetAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			setOrganizations(apiData);
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching organizations:", error);
	} finally {
		setLoading(false);
	}
};
export const getOrganizationById = async (OrganizationId: string): Promise<Organization> => {
	try {
		const response = await adminApiService.get<Organization>(`/Organization/GetOrganizationById`);
		return response.data;
	} catch (error) {
		console.error(`Error fetching organization ${OrganizationId}:`, error);
		throw error;
	}
};




export const createOrganization = async (
    setLoading: any,
    setShowPopup: any,
    setModels: any,
    setShowEditPopup: any,
    inputs: any,
    setSnackbarMessage: any,
    setSnackbarSeverity: any,
    setSnackbarOpen: any,
    onSuccess: (organizationId: string) => void 
): Promise<void> => {
    try {
		const response = await adminApiService.post(`/Organization/CreateOrganization`, JSON.stringify(inputs));
		const responseData = response.data;
        if (!(responseData.Success)) {
            setSnackbarMessage("Organization not created");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            throw new Error("Network response was not ok");
        }


        if (responseData.Success) {
            setShowEditPopup(false);
            setShowPopup(false);
            setSnackbarMessage("Organization created successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);

            onSuccess(responseData.Data);
        } else {
            setSnackbarMessage(responseData.ErrorMessage || "Error creating Organization");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    } catch (error) {
        setSnackbarMessage(`Error creating Organization: ${error}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("Error creating Organization:", error);
    }
};

  

  export const updateOrganization = async (
	setLoading: any,
	setModels: any,
	setShowEditPopup: any,
	userDetails: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	onSuccess: () => void 
  ) => {
	try {
		const response = await adminApiService.post(`/Organization/UpdateOrganization`, userDetails);
		const responseData = response.data;
  
	  if (responseData.Success) {
		setSnackbarMessage("Organization Updated successfully");
		setSnackbarSeverity("success");
		  setSnackbarOpen(true);
		  
  
		setTimeout(onSuccess, 2000); 
	  } else {
		setSnackbarMessage("Organization not Updated successfully");
		setSnackbarSeverity("error");
		setSnackbarOpen(true);
	  }
	} catch (error) {
	  setSnackbarMessage(`Failed to update organization: ${error}`);
	  setSnackbarSeverity("error");
	  setSnackbarOpen(true);
	  console.error("Failed to update organization:", error);
	}
  };
  
export const activateOrganization = async (
	organizationId: string,
	checkedOne: any,
	setShowActivatePopup: any,
	setModels: any,
	setLoading: any,) =>  {
		await adminApiService.post(`/Organization/ActivateOrganization`, organizationId)
			.then((response) => {
				if (!(response.status === 200)) {
					throw new Error("Network response was not ok");
				}
			

			})
			.catch((error) => {
				throw new Error("Error activating an Organization",error);
			});
	
};


export const deactivateOrganization = async (
	organizationId: string,
	checkedOne: any,
	setShowDeactivatePopup:any,
	setModels: any,
	setLoading: any
)=> {
	await adminApiService.post(`/Organization/DeActivateOrganization`, organizationId)

		.then((response) => {
			if (!(response.status === 200)){
				throw new Error("Network response was not ok");
			}
		
		})
		.catch((error) => {
			throw new Error("Error Deactivating an Organization",error);
		});
};

export const deleteOrganization = async (
	organizationId: string,
	setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>,
	getOrganizations: (setModels: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void,
	setModels: React.Dispatch<React.SetStateAction<any[]>>,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
	try {
	  const response = await adminApiService.delete(`/Organization/DeleteOrganization`)
		const responseData = response.data;
	  if (!(responseData.Success===true)) {
		throw new Error("Network response was not ok");
	  }
  	  setShowDeletePopup(false);
	  getOrganizations(setModels, setLoading);
	} catch (error) {
		throw error;
	}
  };


export const getOrganizationByDomain = async (domainName: string): Promise<Organization> => {
    try {
        const response = await adminApiService.get<Organization>(`/Organization/GetOrganizationByDomain?domainName=${domainName}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getOrganizations = async (
    setModels: any,
    setLoading: any,
    skip: number,
    top: number,
    setTotalCount: any,
	sortModel: any,
	filters:any
):Promise<void> => {
    try {
       
		const orderByFields = sortModel.map((sort: any) => `${sort.field}${sort.sort === 'desc' ? ' desc' : ''}`).join(',');
		const requestData = {
            skip,
            top,
            filters:filters,
            orderByFields: orderByFields || 'Name' 
        };

        const response = await adminApiService.post(`/Organization/GetOrganizations`, requestData);
        const apiData = response.data.results;
        setTotalCount(response.data._count);

        if (Array.isArray(apiData)) {
            setModels(apiData);
        }
    } catch (error) {
        console.error("Error fetching organizations:", error);
	}
	finally {
		setLoading(false)
	}
};


export const fetchOrganizations = async (setModelsData: any, setLoading: any) => {
	try {
		const response = await adminApiService.get("/Organization/GetAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			setModelsData(apiData)
			
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching organizations:", error);
		
	}
	finally {
		setLoading(false);
	}

};




export const getOrganizationsData = async (setModels:any,setLoading:any)=> {
	try {
		const response = await adminApiService.get("/Organization/GetAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			return apiData;
		} else {
			console.error("Invalid data format from API:", apiData);
			return [];
		}
	} catch (error) {
		console.error("Error fetching organizations:", error);
		return [];
	}
};

