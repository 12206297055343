import React, { useState, useEffect, ChangeEvent } from "react";
import { getAllOrganizations } from "../../services/OrganizationService";
import { SubmitAccountDetails, fetchAccountsById } from "../../services/AccountService";
import { adminApiService, adminUrl } from "../../services/APIService";
import { TextField, Select, MenuItem, Button, IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./AccountStle.module.scss";
import { useAuth } from "../auth/AuthProvider";
import { User } from "../../models/User";

type InputFields = {
	AccountName: string;
};
type ErrorFields = Partial<InputFields>;

const EditAccount = (props: any) => {
	const {
		showEditPopup,
		setShowEditPopup,
		accountidedit,
		GetAllAccounts,
		setModels,
		setLoading,
		setTotalcount,
		orderByField,
		filters,
		setFilters,
	} = props;
	const [organizations, setOrganizations] = useState<any[]>([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const [skip, setskip] = useState("0");
	const [top, settop] = useState("30");
	const { openSnackbar } = useSnackbar();
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const [user, setUser] = useState<User | null>(null);
	const [isValid, setIsValid] = useState(false);
	//const [totalcount, setTotalcount] = useState(0);
	// const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
	// 	page: 0,
	// 	pageSize: 10,
	// });
	const [errors, setErrors] = useState<ErrorFields>({
		AccountName: "",
	});


	const [AccountDetails, setAccountDetails] = useState({
		AccountId: "",
		AccountName: "",
		AccountType: "",
		CreatedBy: "",
		CreatedDate: "",
		OrganizationId: "",
		UpdatedBy: userDetails?.UserName,
		UpdatedDate: "",
		Active: Boolean(true),
	});

	useEffect(() => {
		if (showEditPopup) {
			fetchAccountDetails(accountidedit);
		}
	}, [showEditPopup]);

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					setUser(parsedUser);	
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		else if (userDetails) {
			setUser(userDetails);	
			if (userDetails) {
				const OrgId = userDetails.OrganizationId ?? '';
				setOrganizationId(OrgId);
			}
		}
	}, []);


	const fetchAccountDetails = async (id: any) => {
		try {
			const responseData = await fetchAccountsById(id);

			if (!responseData) {
				throw new Error("Network response was not ok");
			}

			setAccountDetails({
				AccountId: responseData.AccountId,
				AccountName: responseData.AccountName,
				AccountType: responseData.AccountType,
				CreatedBy: responseData.CreatedBy,
				CreatedDate: responseData.CreatedDate,
				OrganizationId: responseData.OrganizationId,
				UpdatedBy: userDetails?.UserName,
				UpdatedDate: new Date().toUTCString(),
				Active: true,
			});
		} catch (error) {
			console.error("Failed to fetch user details:", error);
		}
	};
	const handleOrganizationDropdownOpen = async () => {
		try {
			const response = await getAllOrganizations(setOrganizations, setLoading);
		} catch (error) {
			console.error("Error fetching organizations:", error);
		}
	};
	const alphanumericRegex = /^[a-zA-Z0-9]*$/;
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		const alphabetWithSpacesRegex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
		let error = "";
		let processedValue = value.trimStart();

		if (name === "AccountType") {
			const selectedOrganization = organizations.find((org) => org.Name === value);
			if (selectedOrganization) {
				setAccountDetails((values) => ({
					...values,
					AccountType: value,
					OrganizationId: OrganizationId
				}));
			}
		} else if (name === "AccountName") {
			// Allow only letters and spaces, remove special characters, numbers, and trim leading spaces
			processedValue = value.replace(/[^a-zA-Z\s]/g, "").replace(/^\s+/, "");

			// Check if the length is less than 5 characters or if the input is only spaces
			if (processedValue.length < 5 || !processedValue.trim()) {
				error = "Minimum 3 characters.";
			}
		}
		setIsValid(true);
		setAccountDetails((prev) => ({ ...prev, [name]: processedValue }));
		setErrors((prev) => ({ ...prev, [name]: error }));
	};

	// const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
	// 	const { name, value } = event.target;
	// 	const alphanumericWithSpacesRegex = /^[a-zA-Z0-9\s]*$/; // Allow letters, numbers, and spaces
	// 	let error = "";
	// 	let processedValue = value;

	// 	if (name === "AccountType") {
	// 		const selectedOrganization = organizations.find((org) => org.Name === value);
	// 		if (selectedOrganization) {
	// 			setAccountDetails((values) => ({
	// 				...values,
	// 				AccountType: value,
	// 				OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863", // Placeholder ID
	// 			}));
	// 		}
	// 	} else if (name === "AccountName") {
	// 		// Remove special characters
	// 		processedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
	// 	}

	// 	setAccountDetails((prev) => ({ ...prev, [name]: processedValue }));

	// 	if (name === "AccountName" && processedValue.length < 5) {
	// 		error = "Account Name must be at least 5 characters.";
	// 	} else {
	// 		setAccountDetails((values) => ({ ...values, [name]: value }));
	// 	}
	// 	setErrors((prev) => ({ ...prev, [name]: error }));
	// };

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const newErrors: ErrorFields = {};

		(Object.keys(AccountDetails) as (keyof InputFields)[]).forEach((key) => {
			if (!AccountDetails[key]) {
				// newErrors[key] = `${key} is required.`;
				// isValid = false;
			} else if (key === "AccountName") {
				if (AccountDetails[key].length < 5) {
					newErrors[key] = "Account Name must be at least 5 characters.";
					setIsValid(false);
				}
				if (/[^a-zA-Z\s]/g.test(AccountDetails[key])) {
					newErrors[key] = "Account Name can only contain letters and numbers.";
					setIsValid(false);
				}
			}
		});

		// Validate AccountName
		if (!AccountDetails.AccountName) {
			setSnackbarMessage("Account Name is required.");
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
			return;
		}

		// If validation passes, prepare the new inputs
		// const newAccountDetails = {
		// 	...AccountDetails,
		// 	// Add any additional fields or transformations if needed
		// };

		setErrors(newErrors);
		if (isValid) {
			setLoading(true);
			try {
				await SubmitAccountDetails(
					setLoading,
					setModels,
					setShowEditPopup,
					AccountDetails,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					openSnackbar,
					orderByField,
					filters
				);

			} catch (error) {

			}
		}
	};
	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		showEditPopup && (
			<div
				className="qadpt-accounteditpopup"
				// style={{ height: "37vh", right: "500px", top: "200px" }}
			>
				<div>
				<div className="qadpt-title">Edit Account</div>
					<svg
						onClick={() => setShowEditPopup(false)}
						className="qadpt-closeicon"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="24"
						height="24"
						viewBox="0 0 50 50"
					>
						<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
					</svg>
				</div>
				<form onSubmit={handleSubmit}>
					{/* <input
						style={{ width: "350px", height: "50px" }}
						type="text"
						name="AccountName"
						value={AccountDetails.AccountName}
						onChange={handleChange}
						placeholder="AccountName*"
						maxLength={25}
						required
					></input> */}
					{/* <TextField
						fullWidth
						label="Account Name"
						name="AccountName"
						onChange={handleChange}
						variant="standard"
						required
						value={AccountDetails.AccountName}
						helperText={errors.AccountName}
						error={!!errors.AccountName}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.AccountName ? "red" : "inherit",
							},
							marginBottom: "16px",
						}}
					/> */}
					<div className={`qadpt-accountcreatefield ${errors.AccountName ? 'qadpt-error' : ''}`}>
						<span> Account name </span>
						<TextField
							name="AccountName"
							value={AccountDetails.AccountName}
							onChange={handleChange}
							// onBlur={handleBlur}
							//label="AccountName*"
							helperText={errors.AccountName}
							variant="outlined"
							error={!!errors.AccountName}
							inputProps={{ maxLength: 50 }}
							className="qadpt-acctfield"
							// sx={{
							// 	"& .MuiFormHelperText-root": {
							// 		color: errors.AccountName ? "red" : "inherit",
							// 	},
							// 	"& .MuiOutlinedInput-root": {
							// 		padding: "0px",
							// 	},
							// 	"& .MuiInputBase-input": {
							// 		height: "0em",
							// 	},
							// 	width: "300px",
							// 	marginBottom: "-2px",
							// }}
						/>
					</div>
					{/* Account Type:
					<select
						name="AccountType"
						style={{ width: "100px", height: "70px" }}
					>
						{organizations.map((org) => (
							<option
								key={org.OrganizationId}
								value={org.Name}
							>
								{org.Name}
							</option>
						))}
					</select>
					 <select
						name="AccountType"
						style={{ width: "100px" }}
						//onChange={handleChange}
						value={AccountDetails.AccountType}
					></select> */}
					<div className="qadpt-save-btn">
						{/* <button
							onClick={() => setShowEditPopup(false)}
						>
							Cancel
						</button> */}
						<button
							// style={{
							// 	backgroundColor: isValid ? "#1c2dd8" : "#aaaef2",
							// }}
							type="submit"
							disabled={isValid === false}
						>
							Save
						</button>
					</div>
				</form>
				{/* <Snackbar
					style={{ zIndex: "9999", position: "relative", top: "-500px", width: "500px", right: "-600px" }}
					open={snackbarOpen}
					autoHideDuration={8000}
					onClose={handleSnackbarClose}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						onClose={handleSnackbarClose}
						severity={snackbarSeverity}
						sx={{ width: "100%" }}
					>
						{snackbarMessage}
					</Alert>
				</Snackbar>{" "} */}
			</div>
		)
	);
};

export default EditAccount;
