import axios from 'axios';
import { adminApiService } from './APIService';
import { AnyMxRecord } from 'dns';

export const getAllGuides = async (skip: number, top: number, filters:any, orderByField: any) => {
    const requestBody = {
        skip,
        top,
        filters: filters, 
        orderByFields: orderByField, 
    };

    try {
        const response = await adminApiService.post(`/Guide/GetAllguides`, requestBody);
        return response.data;
    } catch (error) {
        console.error("Error fetching guides:", error);
        return [];
    }
};

export const DeleteGuideByGuideId = async (GuideId: any) => {
    
    try {
        const response = await adminApiService.post(`/Guide/Deleteguide?guideId=${GuideId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching guides:", error);
        return [];
    }
}

export const CheckGuideNameExists = async (guideName:string, accountId:string,guideType:string) => {
    try {
        const response = await adminApiService.get(`Guide/CheckGuideNameExists`, {
            params: {
                guideName: guideName,
                accountId: accountId,
                guideType:guideType,
                
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error checking guide name existence:", error);
        return [];
    }
}
export const CopyGuide = async (guideId:string, organizationId:string,guideName:string, accountId:string,guideType:string) => {
    try {
        const response = await adminApiService.put(`Guide/CopyGuide`, {
           
            guideId:guideId,
            accountId: accountId,
            guideType:guideType,
            guideName: guideName
            
        });
        return response.data;
    } catch (error) {
        console.error("Error checking guide name existence:", error);
        return [];
    }
}

export const saveGuide = async (guideData:any) => {
    try {
        const response = await adminApiService.post(`/Guide/Saveguide`, guideData);
        return response.data;
    } catch (error) {
        console.error("Error saving guide:", error);
        return null;
    }
};

export const UpdateGuidName = async (guideId:string, organizationId:string,guideName:string, accountId:string,guideType:string) => {
    try {
        const response = await adminApiService.put(`Guide/UpdateguideName`, {
			
            guideId:guideId,
            guideType:guideType,
            guideName: guideName,
            accountId: accountId,
            
        });
        return response.data;
    } catch (error) {
        console.error("Error checking guide name existence:", error);
        return [];
    }
}
export const PublishGuide = async (guideId: string) => {
    try {
        const response = await adminApiService.put(`Guide/PublishGuide`, guideId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error publishing guide:", error);
        return [];
    }
}
export const UnPublishGuide = async (guideId: string) => {
    try {
        const response = await adminApiService.put(`Guide/UnPublishGuide`, guideId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error Unpublishing guide:", error);
        return [];
    }
}

export const SubmitUpdateGuid = async (newGuide: any) => {
	try {
		const response = await adminApiService.post(`/Guide/Updateguide`, newGuide);

		if (response) {
			return response.data
		} else {
			console.error("Failed to update guide");
		}
	} catch (error) {
		console.error("Error update guide:", error);
	} finally {
	}
};
export const GetGudeDetailsByGuideId = async (GuideId: any) => {
	try {
		const response = await adminApiService.get(`Guide/GetGuideDetails?guideId=${GuideId}`);
		if (response) {
			return response.data
		} else {
			console.error("Failed to update guide");
		}
	} catch (error) {
		console.error("Error update guide:", error);
	} finally {
	}
};

export const SavePageTarget = async (PageTarget: any) => {
    try {
        const response = await adminApiService.post(`/Guide/SavePageTarget`, PageTarget);
        if (response) {
            return response.data
        } 
        
    } catch (error) {
        throw error;
    }
}
export const DeletePageTarget = async (reqObj:any) => {
    try {
        
        const response = await adminApiService.put(`/Guide/DeletePageTargets`, reqObj);
        if (response) {
            return response.data
        } 
        
    } catch (error) {
        throw error;
    }
}

export const GetPageTargets = async (GuideId: string) => {
    try {
        const response = await adminApiService.get(`/Guide/GetPageTargets?guideId=${GuideId}`);
        if (response) {
            return response.data
        } 
        
    } catch (error) {
        throw error;
    }
}

export const UpdatePageTarget = async (pageTargets:any) => {
    try {
        // for (const PageTarget of pageTargets) {
        //     const { PageTargetId, GuideId, OrganizationId, Condition, Operator, Value } = PageTarget;
            const response = await adminApiService.put(
                `Guide/UpdatePageTargets`,pageTargets
            );
            // const response = await adminApiService.put(
            //     `Guide/UpdatePageTargets?PageTargetId=${PageTargetId}&GuideId=${GuideId}&OrganizationId=${OrganizationId}&Condition=${Condition}&Operator=${Operator}&Value=${Value}`
            // );
            if (response) {
                return response.data;
            } else {
                console.log("Error while saving the pagetarget");
           // }
        }
    } catch (error) {
        console.log("Error while saving the pagetarget", error);
    }
};


export const GetAccountsList = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalCount: any,
	orderByField: any,
	filters: any
) => {
	try {
		setLoading(true);
		const requestBody = {
			skip:-1,
			top:-1,
			filters: filters ? filters : "", 
			orderByFields: orderByField, 
		};
		const response = await adminApiService.post(`/Account/GetAccountsByOrgId`, requestBody);
		let apiData = response.data.results;
		if (typeof setTotalCount === 'function') {
            setTotalCount(response.data._count);
          }
		

		if (Array.isArray(apiData)) {
			apiData = apiData.map((account) => ({
				...account,
				CreatedDate: account.CreatedDate.split("T")[0],
				UpdatedDate: account.UpdatedDate ? account.UpdatedDate.split("T")[0] : null,
			}));
			setModels(apiData);
		} 
	} catch (error) {
        throw error;
	} finally {

	}
};

